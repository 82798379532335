html,
body,
#root {
  height: 100%;
}

.para-text {
  font-family: 'Gilroy-Bold', sans-serif;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.015em;
  text-align: left;
  color: #5F5F5F;
}

.small-text {
  font-family: 'Gilroy-Medium', sans-serif;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.02em;
  text-align: left;
}

.smallpara-text {
  font-family: 'Gilroy-Medium', sans-serif;
  color: #157AD7;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.02em;
  text-align: left;
}

.appstore-image {
  width: 120px !important;
}

.contrast-text {
  color: #C64959;
}

.input-text {
  font-family: 'Gilroy-Bold', sans-serif;
  line-height: 17px;
  letter-spacing: 0.015em;
  text-align: left;
}

.input-text::-webkit-input-placeholder {
  font-size: 13px !important;
  color: #B4B4B4 !important;
}

.top-left-privacy-logo {
  width: 280px !important;
  padding-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
}

select {
  appearance: none; /* removes the default appearance */
  background-color: #fff;
  background-image: url('resources/images/Dropdown.svg'); /* replace with your icon's path */
  background-repeat: no-repeat;
  background-position: right center ;
  background-size: 10px 10px;
  background-position: calc(100% - 10px) center;
  padding-right: 25px !important; /* space for the icon */
}

.join-button {
  box-shadow: 0px 2px 6px 0px #00000040;
  font-family: 'Gilroy-Medium', sans-serif !important;
  font-size: 16px !important;
  line-height: 2px;
  letter-spacing: 0.015em;
  text-align: center;
  width: 100%;
}

.form-control {
  font-size: 13px !important;
  color: #B4B4B4 !important;
  line-height: 17px;
  letter-spacing: 0.015em;
  text-align: left;
}

@media (max-width: 991.98px) {

  .hide-on-mobile {
    display: block;
  }

  .overlap-left,
  .overlap-right {
    margin-left: 10px !important;
  }

  .top-left-logo {
    width: 177px !important;
    height: auto;
    margin-top: 25px;
    margin-left: 3px;
  }


  .social-text {
    margin-left: 15px !important;
    padding-bottom: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 15px !important;
  }

  .social-icon {
    color: #333333;
    margin-right: 20px;
    font-size: 24px;
  }

  .overlap-bottom-right {
    margin-left: 10px !important;
  }

  .header-text {
    font-family: 'Gilroy-Bold', sans-serif;
    font-size: 40px;
    line-height: 53px;
    letter-spacing: 0em;
    text-align: left;
    color: #333333;
  }



}

@media (min-width: 992px) {
  .overlap-left {
    margin-left: -150px !important;
    z-index: 1;
    padding-right: 150px !important;
  }

  .overlap-right {
    padding-left: 150px !important;
    padding-top: 150px !important;
    padding-right: 150px !important;
  }

  .top-left-logo {
    top: 0;
    left: 0;
    width: 177px !important;
    height: auto;
    margin-top: 25px;
    margin-left: 25px;
  }

  .overlap-bottom-right {
    margin-left: 10px !important;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 25px !important;
  }



  .social-text {
    padding-bottom: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 15px !important;
  }

  .no-underline {
    text-decoration: none !important;
}

  .social-icon {
    margin-right: 25px;
    font-size: 24px;
    color: #333333;
  }

  .make-grey{
    color: #333333;
  }

  .hide-on-mobile {
    display: none;
  }

  .header-text {
    font-family: 'Gilroy-Bold', sans-serif;
    font-size: 45px;
    line-height: 53px;
    letter-spacing: 0em;
    text-align: left;
    color: #333333;
  }

}


/* Styles for large screens and up */
@media (min-width: 1462px) {
  .overlap-left {
    margin-left: -150px !important;
    z-index: 1;
    padding-right: 150px !important;
  }

  .overlap-right {
    padding-left: 350px !important;
    padding-top: 150px !important;
    padding-right: 100px !important;
  }

  .top-left-logo {
    top: 0;
    left: 0;
    width: 177px !important;
    height: auto;
    margin-top: 25px;
    margin-left: 25px;
  }

  .overlap-bottom-right {
    margin-left: 10px !important;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 25px !important;
  }



  .social-text {
    padding-bottom: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 15px !important;
  }

  .social-icon {
    margin-right: 25px;
    font-size: 24px;
    color: #333333;
  }

  .hide-on-mobile {
    display: none;
  }

  .header-text {
    font-family: 'Gilroy-Bold', sans-serif;
    font-size: 45px;
    line-height: 50px;
    letter-spacing: 0em;
    text-align: left;
    color: #333333;
  }

}

.end-text {
  font-family: 'Inter-Regular';
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.01em;
  display: flex;
  justify-content: center;
  align-items: center;
  
}


.endsuccess-text {
  
  font-family: 'Inter-Regular';
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;

  justify-content: center;
  align-items: left;
}

.contact-text{
  font-family: 'Inter-Regular';
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;

}

.success-check {
  width: 52px;
  height: 52px;
  padding-top: 10px;
}

.make-underline {
  text-decoration: underline;
}

.make-bold {
  font-weight: bold;
}

.privacy-policy {
  padding-top: 30px;
  padding-bottom: 50px;
  padding-left: 100px;
  padding-right: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.privacy-policy-text{
  color: #0C67FA;
  font-family: 'Inter-Regular';
  text-decoration: underline;
}

.heading-font {
  color: #000;
  font-family: 'Inter-Bold';
  font-size: 14px;
  font-style: normal;
  font-weight: 100;
  line-height: normal;
}

.para-font {
  color: #000;
  font-family: 'Inter-Regular';
  font-size: 14px;
  font-style: normal;
  font-weight: 100;
  line-height: normal;
}

@font-face {
  font-family: 'Gilroy-Bold';
  src: url('./resources/font/Gilroy-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy-Medium';
  src: url('./resources/font/Gilroy-Medium.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Inter-Regular';
  src: url('./resources/font/Inter-Regular.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Inter-Medium';
  src: url('./resources/font/Inter-Medium.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Inter-Bold';
  src: url('./resources/font/Inter-Bold.ttf') format('truetype');
  font-style: normal;
}